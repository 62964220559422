import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';

import WarningIcon from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg?react';

export function ReloadPageModal() {
  return (
    <Modal dismissable={false} disableOutsideClick={true}>
      <WarningIcon className="fill-current w-10 h-10 text-[#FFB100]" />
      <Modal.Title>Something went wrong</Modal.Title>
      <p>An error was detected while trying to complete your request. Please reload the page and try again.</p>
      <div className="relative">
        <Modal.Actions>
          <Button onClick={() => window.location.reload()}>Reload page</Button>
        </Modal.Actions>
      </div>
    </Modal>
  );
}
