import { useMainMachine } from '../../../utils/useMainMachine';
import { Button } from '../basic-ui/Button';
import { ACTIONS } from '../../../state';
import { BUTTON_TYPES } from '../../constants';

import RedoIcon from '@crazyegginc/hatch/dist/images/icon-redo.svg?react';

export function UndoRedo() {
  const { machine, useSelector } = useMainMachine();
  const canUndo = useSelector(({ context }) => context.canUndo);
  const canRedo = useSelector(({ context }) => context.canRedo);

  return (
    <>
      <Button
        disabled={!canUndo}
        onClick={() => machine.send({ type: ACTIONS.UNDO })}
        variant={BUTTON_TYPES.SECONDARY}
        className="mr-1.25"
      >
        <RedoIcon className="w-4 h-4 fill-current scale-x-[-1]" aria-label="undo" />
      </Button>
      <Button disabled={!canRedo} onClick={() => machine.send({ type: ACTIONS.REDO })} variant={BUTTON_TYPES.SECONDARY}>
        <RedoIcon className="w-4 h-4 fill-current" aria-label="redo" />
      </Button>
    </>
  );
}
