import Honeybadger from '@honeybadger-io/js';

import { initApi, initCrazyAI } from './mainMachine';
import { ACTIONS } from './actions';
import {
  extractContent,
  getTagCount,
  removeSVGs,
  restoreSVGs,
  removeComments,
  getInnerHtml,
  changeInnerHtml,
  isStringHTML,
} from '../utils/html';
import { getVoice } from '../utils/utils';
import { capitalizeFirstLetter } from '../utils/string';
import { VOICES } from '../editor-ui/constants';
import { hasSearchParam, setSearchParam } from '../utils/location';

export async function apiInit() {
  const api = await initApi();
  const anonymousId = await api.getAnonymousId();
  const userId = await api.isLoggedIn();

  if (!hasSearchParam('editorid')) {
    const { editorId } = await api.getLastSession();
    if (editorId) {
      setSearchParam('editorid', editorId);
    }
  }

  return { api, anonymousId, userId };
}

export async function sessionInit({ input: { url, api } }) {
  await api.createPage(url);
  const editor = await api.getEditor();
  const pageContext = await api.getAIContext();
  const pageId = editor.pages[0].id;
  return { editor, pageId, pageContext };
}

export async function sharingSessionInit({ input: { id, api } }) {
  await api.loadEditor(id);
  const editor = await api.getEditor();
  const pageContext = await api.getAIContext();
  const pageId = editor.pages[0].id;
  return { editor, pageId, pageContext };
}

export async function aiInit() {
  const ai = await initCrazyAI();

  return { ai };
}

export async function apiEventListeners({ sendBack, input }) {
  const { api } = input;
  const elementSelected = (event) => {
    sendBack({ type: ACTIONS.ELEMENT_SELECTED, el: event.detail });
  };
  const elementUnSelected = () => {
    sendBack({ type: ACTIONS.ELEMENT_UNSELECTED });
  };
  const selectedElementUpdated = (event) => {
    sendBack({ type: ACTIONS.SELECTED_ELEMENT_UPDATED, el: event.detail });
  };
  const popupsDetected = () => {
    sendBack({ type: ACTIONS.POPUPS_DETECTED });
  };
  const editorStatusChange = (event) => {
    sendBack({ type: ACTIONS.STATUS_CHANGE, detail: event.detail });
  };
  const elementLocked = () => {
    sendBack({ type: ACTIONS.ELEMENT_LOCKED });
  };
  const errorDetected = (event) => {
    sendBack({ type: ACTIONS.ERROR_DETECTED, error: event.detail });
  };

  api.addEventListener('elementSelected', elementSelected);
  api.addEventListener('elementUnSelected', elementUnSelected);
  api.addEventListener('selectedElementUpdated', selectedElementUpdated);
  api.addEventListener('popupsDetected', popupsDetected);
  api.addEventListener('editorStatus', editorStatusChange);
  api.addEventListener('elementLocked', elementLocked);
  api.addEventListener('error', errorDetected);

  const removeListeners = () => {
    api.removeListener('elementSelected', elementSelected);
    api.removeListener('elementUnSelected', elementUnSelected);
    api.removeListener('selectedElementUpdated', selectedElementUpdated);
    api.removeListener('popupsDetected', popupsDetected);
    api.removeListener('editorStatus', editorStatusChange);
    api.removeListener('elementLocked', elementLocked);
    api.removeListener('error', errorDetected);
  };

  // just in case we missed the event already
  popupsDetected();

  return () => {
    removeListeners();
  };
}

export async function apiAddPage({ input: { url, api } }) {
  await api.createPage(url);
  const editor = await api.getEditor();
  const pageContext = await api.getAIContext();
  const newPageId = editor.pages.find((p) => p.url === url).id;
  return { editor, pageId: newPageId, pageContext };
}

export async function apiSave({ input: { api, asNew, versions, usedVoice, activeVersion } }) {
  let name = usedVoice ? `${capitalizeFirstLetter(usedVoice)} voice` : 'Edited';
  // don't rename if it's the same type of name
  if (!asNew && activeVersion && activeVersion.name.includes(name)) {
    name = activeVersion.name;
  } else {
    const regexp = new RegExp(`^${name}\\s?v?(\\d*)$`);
    const versionNumber = versions.reduce((acc, v) => {
      const m = v.name?.match(regexp);
      if (m) {
        if (Number(m[1]) > acc) {
          return m[1];
        } else if (acc === 0) {
          return 1;
        }
      }
      return acc;
    }, 0);
    if (versionNumber > 0) {
      name = `${name} v${+versionNumber + 1}`;
    }
  }

  let versionId;
  if (asNew) {
    versionId = await api.saveAsNew(name);
  } else {
    versionId = await api.save(name);
  }
  const editor = await api.getEditor();

  return { editor, versionId, asNew };
}

export async function apiSelectVersion({ input: { api, pageId, versionId } }) {
  await api.loadVersion(pageId, versionId);

  const pageContext = await api.getAIContext();

  return { pageContext };
}

export function beforeUnloadListener({ receive }) {
  // to trigger a browser-generated confirmation dialog that asks users to confirm if
  // they really want to leave the page when they try to close or reload it, or navigate somewhere else
  const beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };
  let listening = false;

  const listen = () => {
    window.addEventListener('beforeunload', beforeUnloadHandler);
    listening = true;
  };
  const removeListener = () => {
    window.removeEventListener('beforeunload', beforeUnloadHandler);
    listening = false;
  };

  receive((event) => {
    if (event.type === ACTIONS.DIRTY_CHANGED) {
      if (event.dirty && !listening) {
        listen();
      } else if (!event.dirty && listening) {
        removeListener();
      }
    }
  });

  return () => {
    removeListener();
  };
}

export async function generateFullPage({ input: { api, ai, voice, pageContext } }) {
  const usedVoice = getVoice(voice); // pick randomly if "lucky" is used
  let had_locked_elements = false; // needed for internal metrics
  const elements = await api.getAllTextElements();

  const allSelectors = [];
  const transformedElements = { headline: [], cta: [], longText: [] };
  // handle identical and 'similar' elements
  ['headline', 'cta', 'longText'].forEach((type) =>
    elements[type]?.forEach((el) => {
      if (!had_locked_elements) {
        if (el.html.includes('data-locked=""')) {
          had_locked_elements = true;
        }
      }
      // ignore locked element
      if (el.locked !== true) {
        allSelectors.push(el.selector);
        // totally identical
        const index = transformedElements[type].findIndex((e) => e.html === el.html);
        if (index > -1) {
          transformedElements[type][index].selector.push(el.selector);
        } else {
          // text is identical and tag count identical
          const index2 = transformedElements[type].findIndex(
            (e) => e.text === el.text && getTagCount(removeSVGs(e.html)) === getTagCount(removeSVGs(el.html)),
          );
          if (index2 > -1) {
            transformedElements[type][index2].similar.push(el);
          } else {
            transformedElements[type].push({ ...el, selector: [el.selector], similar: [] });
          }
        }
      } else {
        had_locked_elements = true;
      }
    }),
  );

  await api.setLoadingStatus(allSelectors);
  await api.openGroup({ voice: usedVoice });

  // First we do all the headlines
  await Promise.all(
    transformedElements.headline.map((item, index) =>
      generateElement({ item, index, type: 'headline', api, ai, pageContext, voice: usedVoice, transformedElements }),
    ),
  );
  // Then the rest, as they use the new headlines
  await Promise.all(
    ['cta', 'longText'].flatMap((type) =>
      transformedElements[type].map((item, index) =>
        generateElement({ item, index, type, api, ai, pageContext, voice: usedVoice, transformedElements }),
      ),
    ),
  );

  await api.closeGroup();

  const voiceForMetric = voice === VOICES.LUCKY ? `${voice}: ${usedVoice}` : usedVoice;

  return { voice: voiceForMetric, had_locked_elements };
}

async function generateElement({ item, index, type, api, ai, pageContext, voice, transformedElements }) {
  return new Promise((resolve, reject) => {
    const isHTML = isStringHTML(item.html);
    const content = isHTML ? removeComments(item.html) : item.text;

    const origTagCount = getTagCount(removeSVGs(content));

    const params = {
      url: pageContext.url,
      content: removeSVGs(content), // AI is very slow to "copy" SVG content, so we remove it before sending it
      title: pageContext.title || '',
      meta_description: pageContext.meta_description || '',
      voice,
      suggestion_count: 1,
      element_type: type,
    };

    if (type !== 'headline') {
      params.nearest_headline =
        transformedElements.headline.find((headline) => headline.text === item.closestHeadline)?.newText ?? '';
    }

    ai.ask(
      'page-editor-generator',
      params,
      {
        url: `${location.pathname}${location.search}`,
      },
      {
        onResponse: (p) => {
          const response = p.message
            .split(/\n-|^-/)
            .slice(1)
            .filter((x) => getTagCount(x) === origTagCount)
            .map((x) => x.trim())[0];

          if (!response) {
            Honeybadger.notify('Unexpected response format from AI.', {
              context: { inputContent: content, response: p.message },
            });
            console.error('Unexpected response format from AI. Details below:');
            console.log('content: ', content); // eslint-disable-line
            console.log('rawResonse ', p.message); // eslint-disable-line
            console.log('response: ', response); // eslint-disable-line
          }
          // restore removed SVGs, and remove data-locked attributes, that the API added
          const newHTML = restoreSVGs(content, response).replaceAll(/data-locked=""/g, '');

          item.selector.forEach((s) => {
            if (isHTML) {
              api.setHTML(newHTML, s);
            } else {
              api.setText(response, s);
            }
          });
          item.similar.forEach((h) => {
            if (isHTML) {
              const content2 = removeComments(h.html);
              const changed = changeInnerHtml(content2, getInnerHtml(response));

              const result = restoreSVGs(content2, changed);
              api.setHTML(result, h.selector);
            } else {
              api.setText(response, h.selector);
            }
          });
          if (type === 'headline') {
            if (isHTML) {
              transformedElements.headline[index].newText = extractContent(newHTML);
            } else {
              transformedElements.headline[index].newText = extractContent(response);
            }
          }
          resolve();
        },
        onError: () => reject(),
      },
    );
  });
}

export async function apiPublish({ input: { api, pageId, versionId } }) {
  await api.publish(pageId, versionId);
}
