import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';

import './editor.css';
import pkg from '../../package.json';

import { CrazyAIProvider } from '../ai/CrazyAI';
import { ModalCenter } from '../modal/ModalCenter';
import { NotificationCenter } from '../notification/NotificationCenter';
import { MainMachineContext } from '../state';
import { PageEditor } from './PageEditor';
import { FullScreenLoader } from './components/basic-ui/FullScreenLoader';

const HBconfig = {
  apiKey: 'hbp_PGx7iTljUPeaH7bu7ReCiRQL5Jwbgm1OUrwH',
  environment: process.env.NODE_ENV ?? import.meta.env.MODE,
  revision: pkg.version,
};
const honeybadger = Honeybadger.configure(HBconfig);

export function Editor() {
  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <MainMachineContext.Provider>
        <CrazyAIProvider>
          <FullScreenLoader />
          <ModalCenter />
          <NotificationCenter />
          <PageEditor />
        </CrazyAIProvider>
      </MainMachineContext.Provider>
    </HoneybadgerErrorBoundary>
  );
}
