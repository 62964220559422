import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns';
import { getDomain } from 'tldts';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Modal } from '../../../modal/Modal';

export function HistoryModal() {
  const { useSelector } = useMainMachine();
  const page = useSelector(({ context }) => context.editor.pages.find((p) => p.id === context.activePageId));

  const domain = getDomain(page.url);

  const pathname = new URL(page.url).pathname;
  let displayName = domain;
  if (pathname !== '/') {
    displayName = `${domain}${pathname}`;
  }

  const reversedHistory = [...page.history].reverse();

  return (
    <Modal dialogClassName="!top-[150px] !translate-y-0 !w-[700px] !min-h-[250px] !pr-0 !pl-[30px] !pt-6">
      <Modal.Title className="!mb-0 leading-none">Page changelog</Modal.Title>

      <p className="text-gray-200">
        A breakdown of all activity made on <span className="text-white">{displayName}</span>
      </p>
      <div className="flex flex-col styled-scrollbar relative mt-[30px] max-h-full overflow-y-auto pr-10">
        <div className="relative pl-9">
          <div className="absolute left-5 top-0.5 h-[calc(100%_-_2px)] w-0.5 bg-gray-500" />
          {reversedHistory.map((item) => (
            <div
              key={`${item.timestamp}-${item.body}-${item.selector}`}
              className="mb-5 text-xs relative flex justify-between"
              data-testid="history-entry"
            >
              <div className="absolute -left-5 top-0.5 w-[11px] h-[11px] rounded-full border-2 border-gray-500 bg-gray-900" />
              <div>
                <div className="text-gray-200">{item.headline}</div>
                <div className="text-white">{item.body}</div>
              </div>
              <div className="text-2xs text-gray-200 shrink-0 ml-5">
                {formatDistanceToNowStrict(fromUnixTime(Math.floor(item.timestamp)), { addSuffix: true })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
