import { useState } from 'react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Button } from '../basic-ui/Button';
import { Spinner } from '../basic-ui/Spinner';
import { BUTTON_TYPES } from '../../constants';
import { ACTIONS, STATES } from '../../../state';

export function PublishButton() {
  const { machine, useSelector } = useMainMachine();
  const userId = useSelector(({ context }) => context.userId);
  const activePageId = useSelector(({ context }) => context.activePageId);
  const isActiveLive = useSelector(
    ({ context }) =>
      context.activeVersionId == null ||
      context.editor.pages.find((p) => p.id === activePageId)?.versions.find((v) => v.id === context.activeVersionId)
        ?.published_at != null,
  );
  const isPublishing = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.GENERAL]: STATES.PUBLISHING } }),
  );
  const isDirty = useSelector(({ context }) => context.isDirty);

  const [showTooltip, setShowTooltip] = useState(false);

  const disabled = !userId || !activePageId || isPublishing || isActiveLive || isDirty;

  let tooltipText;
  if (!userId) {
    tooltipText = 'You must be logged in to publish.';
  } else if (isDirty) {
    tooltipText = 'Please save your changes before publishing.';
  }

  return (
    <div
      className="relative"
      onMouseEnter={() => {
        setShowTooltip(true);
      }}
      onPointerEnter={() => {
        setShowTooltip(true);
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      onPointerLeave={() => {
        setShowTooltip(false);
      }}
    >
      {showTooltip && tooltipText ? (
        <>
          <div className="absolute p-2.5 rounded shadow-md bg-gray-600 bottom-full -translate-y-[10px] right-0 whitespace-nowrap">
            {tooltipText}
            <div
              className={classNames(
                'absolute h-0 w-0 border-5 border-transparent',
                'top-full -translate-x-1/2 right-10 border-t-gray-600',
              )}
            />
          </div>
        </>
      ) : null}
      <Button
        variant={userId ? BUTTON_TYPES.PUBLISH : BUTTON_TYPES.PRIMARY}
        disabled={disabled}
        onClick={() => machine.send({ type: ACTIONS.PUBLISH })}
      >
        {!isPublishing ? (
          <>Publish page</>
        ) : (
          <>
            <Spinner className="w-4 h-4" />
            <span>Publishing...</span>
          </>
        )}
      </Button>
    </div>
  );
}
