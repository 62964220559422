import { createPortal } from 'react-dom';
import { useSelector } from '@xstate/react';

import { useMainMachine } from '../utils/useMainMachine';
import { MODAL_MACHINE_ID } from '../state';

export function ModalCenter() {
  const { useSelector: useMainSelector } = useMainMachine();

  const ref = useMainSelector((state) => state.children[MODAL_MACHINE_ID].ref);
  const component = useSelector(ref, (state) => state.context.component);

  return createPortal(<Content component={component} />, document.body);
}

function Content({ component }) {
  if (!component) {
    return null;
  }

  return <>{component}</>;
}
