import { useRef } from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';
import { Input } from '../basic-ui/Input';
import { Spinner } from '../basic-ui/Spinner';

import { ensureProtocol } from '../../../utils/url';
import { STATES, ACTIONS } from '../../../state';

const validationSchema = (pages) =>
  yup.object().shape({
    url: yup
      .string()
      .required('Please provide a URL.')
      .url('Please provide a valid URL.')
      .test('not-existing-page', 'This page is already added.', (value) => {
        let newPage;
        try {
          const url = new URL(value);
          newPage = `${url.origin}${url.pathname}`;
        } catch {
          //noop
        }
        if (!newPage) return true;

        return pages.every((p) => {
          const url = new URL(p.url);
          const displayName = `${url.origin}${url.pathname}`;
          return newPage !== displayName;
        });
      }),
  });

export function AddPageModal({ ...props }) {
  const { useSelector, machine } = useMainMachine();
  const isLoading = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.GENERAL]: STATES.ADDING_PAGE } }),
  );
  const pages = useSelector(({ context }) => context.editor.pages);
  let ref = useRef();

  return (
    <Modal
      dialogClassName="!top-[150px] !translate-y-0"
      onDismiss={() =>
        machine.send({
          type: ACTIONS.CANCEL,
        })
      }
      {...props}
    >
      <Modal.Title>Add a Page</Modal.Title>

      <p>Add a page to start editing.</p>

      <Formik
        initialValues={{ url: '' }}
        validationSchema={validationSchema(pages)}
        onSubmit={(values) => {
          machine.send({
            type: ACTIONS.ADD_PAGE,
            url: values.url,
          });
        }}
      >
        {({ values, errors, touched, setFieldValue, handleBlur }) => (
          <Form ref={ref}>
            <div className="mt-[30px]">
              <Input
                label="Page URL"
                name="url"
                size="lg"
                value={values.url}
                onChange={async (e) => {
                  await setFieldValue('url', ensureProtocol(e.target.value), false);
                }}
                onBlur={(e) =>
                  setTimeout(() => {
                    ref.current ? handleBlur(e) : null; // only update if component is still mounted
                  }, 200)
                }
                error={touched.url && errors.url ? errors.url : null}
                autoFocus
              />
            </div>

            <Modal.Actions>
              <Modal.Cancel
                disabled={isLoading}
                onClick={() =>
                  machine.send({
                    type: ACTIONS.CANCEL,
                  })
                }
              />
              <Button type="submit" size="lg" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner />
                    <span className="ml-2">Adding...</span>
                  </>
                ) : (
                  'Add Page'
                )}
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
