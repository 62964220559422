import classNames from 'classnames';

import { useMainMachine } from '../utils/useMainMachine';
import { SideBar } from './components/_side/SideBar';
import { BottomBar } from './components/_bottom/BottomBar';
import { TopBar } from './components/_top/TopBar';
import { STATES } from '../state/states';

export function PageEditor() {
  const { useSelector } = useMainMachine();
  const isMobile = useSelector((state) => state.matches(STATES.MOBILE_ACCESS));
  //console.log(useSelector((state) => state));

  if (isMobile) {
    return null;
  }

  return (
    <div
      className={classNames(
        'w-full min-w-[1200px] min-h-[600px] h-svh max-h-svh relative',
        'bg-gray-600 text-white text-sm font-sans',
        'flex flex-col justify-between',
      )}
    >
      <>
        <main className="flex grow max-h-[calc(100%_-_60px)] w-full">
          <div className="grow flex flex-col items-center min-w-[880px] mx-5">
            <div className="max-w-[1100px] xl:max-w-[min(1100px,_95%)] 2xl:max-w-[min(1440px,_90%)] w-full grow flex flex-col items-center">
              <TopBar />
              <div id="page-wrapper" className="grow w-full" />
            </div>
          </div>
          <SideBar />
        </main>
        <BottomBar />
      </>
    </div>
  );
}
