import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';

import { useMainMachine } from '../../../utils/useMainMachine';
import { DisclosureButton } from '../basic-ui/DisclosureButton';
import { PulsatingDot } from '../basic-ui/PulsatingDot';
import { inflect } from '../../../utils/string';
import { ACTIONS } from '../../../state';
import { VOICES } from '../../constants';

import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';
import ImageIcon from '@crazyegginc/hatch/dist/images/icon-image.svg?react';

export function Versions() {
  const { useSelector, machine } = useMainMachine();
  const versions = useSelector(
    ({ context }) => context.editor.pages.find((p) => p.id === context.activePageId)?.versions ?? [],
  );
  const pageThumbnail = useSelector(
    ({ context }) => context.editor.pages.find((p) => p.id === context.activePageId)?.thumbnailUrl,
  );
  const open = useSelector(({ context }) => context.versionsOpen);

  const notLiveVersions = versions.filter((v) => v.published_at == null);
  const liveVersion = versions.find((v) => v.published_at != null) ?? {
    id: null,
    published_at: true,
    thumbnailUrl: pageThumbnail,
  };

  const versionsWithLive = [liveVersion, ...notLiveVersions];

  return (
    <div className="flex flex-col mt-5" data-testid="versions">
      <div className="flex items-center justify-between">
        <div>
          <span className="font-semibold">Versions</span>
          <span className="ml-2.5 text-gray-200">
            {versions.length} {inflect('version', versions.length)}
          </span>
        </div>

        <DisclosureButton
          open={open}
          onClick={() => machine.send({ type: ACTIONS.TOGGLE_VERSIONS })}
          contentId="versions-content"
        />
      </div>
      <div className="overflow-hidden">
        <motion.div
          initial={false}
          animate={
            open
              ? {
                  height: 'auto',
                  opacity: 1,
                }
              : {
                  height: 0,
                  opacity: 0,
                }
          }
          transition={{ duration: 0.4, ease: 'linear' }}
          className="mt-2.5 flex flex-col"
          id="versions-content"
        >
          <motion.div
            animate={open ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.2, ease: 'linear' }}
            data-testid="versions-list"
          >
            {versionsWithLive.map((v) => (
              <Version
                key={v.id}
                id={v.id}
                live={v.published_at != null}
                thumb={v.thumbnailUrl}
                updatedAt={v.updated_at}
                name={v.name}
                voice={v.metadata?.voice}
              />
            ))}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

function Version({ id, live, thumb, updatedAt, name, voice }) {
  const { useSelector, machine } = useMainMachine();
  const active = useSelector(({ context }) => context.activeVersionId == id);
  const [hasError, setHasError] = useState(!thumb);

  let displayDate = '';

  if (updatedAt && !isNaN(Date.parse(updatedAt))) {
    displayDate = new Date(updatedAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  // if thumb changes, reset error
  useEffect(() => {
    if (thumb) {
      setHasError(false);
    }
  }, [thumb]);

  return (
    <motion.button
      layout
      transition={{ duration: 1 }}
      type="button"
      className="mt-5 first:mt-0 text-left relative group overflow-hidden rounded-md"
      onClick={() => machine.send({ type: ACTIONS.SELECT_VERSION, versionId: id })}
    >
      {hasError ? (
        <div className="absolute w-[230px] !max-w-[230px] h-[160px] rounded-md flex items-center justify-center bg-gray-500">
          <ImageIcon className="w-11 h-11 fill-current text-gray-900" />
        </div>
      ) : (
        <img
          src={thumb}
          alt="version thumbnail"
          className={classNames('absolute w-[230px] !max-w-[230px] h-[160px] object-contain rounded-md bg-gray-500')}
          onError={() => setHasError(true)}
        />
      )}
      <div
        id="overlay"
        className={classNames(
          'absolute w-[230px] h-[160px]',
          live && 'bg-transparent',
          !live &&
            !voice && {
              'group-hover:bg-blue-300/20': true,
              'bg-blue-300/20': active,
            },
          !live &&
            voice === VOICES.CONFIDENT && {
              'group-hover:bg-voice-confident/20': true,
              'bg-voice-confident/20': active,
            },
          !live &&
            voice === VOICES.PROFESSIONAL && {
              'group-hover:bg-voice-professional/20': true,
              'bg-voice-professional/20': active,
            },
          !live &&
            voice === VOICES.FRIENDLY && {
              'group-hover:bg-voice-friendly/20': true,
              'bg-voice-friendly/20': active,
            },
          !live &&
            voice === VOICES.REASSURING && {
              'group-hover:bg-voice-reassuring/20': true,
              'bg-voice-reassuring/20': active,
            },
          !live &&
            voice === VOICES.ENGAGING && {
              'group-hover:bg-voice-engaging/20': true,
              'bg-voice-engaging/20': active,
            },
        )}
      />
      <div
        id="inside-border"
        className={classNames(
          'relative w-[230px] h-[160px] rounded-md flex items-center justify-center border-[3px]',
          !active && 'border-transparent',
          live && {
            'group-hover:border-green-500': true,
            'border-green-500': active,
          },
          !live &&
            !voice && {
              'group-hover:border-blue-300': true,
              'border-blue-300': active,
            },
          !live &&
            voice === VOICES.CONFIDENT && {
              'group-hover:border-voice-confident': true,
              'border-voice-confident': active,
            },
          !live &&
            voice === VOICES.PROFESSIONAL && {
              'group-hover:border-voice-professional': true,
              'border-voice-professional': active,
            },
          !live &&
            voice === VOICES.FRIENDLY && {
              'group-hover:border-voice-friendly': true,
              'border-voice-friendly': active,
            },
          !live &&
            voice === VOICES.REASSURING && {
              'group-hover:border-voice-reassuring': true,
              'border-voice-reassuring': active,
            },
          !live &&
            voice === VOICES.ENGAGING && {
              'group-hover:border-voice-engaging': true,
              'border-voice-engaging': active,
            },
        )}
      >
        {active ? (
          <>
            <div
              className={classNames(
                'absolute top-0 left-0 w-11 h-11 rotate-45 -translate-x-1/2 -translate-y-1/2',
                live
                  ? 'bg-green-500'
                  : {
                      'bg-blue-300': !voice,
                      'bg-voice-confident': voice === VOICES.CONFIDENT,
                      'bg-voice-professional': voice === VOICES.PROFESSIONAL,
                      'bg-voice-friendly': voice === VOICES.FRIENDLY,
                      'bg-voice-reassuring': voice === VOICES.REASSURING,
                      'bg-voice-engaging': voice === VOICES.ENGAGING,
                    },
              )}
            />
            <TickIcon
              className={classNames('absolute h-3 w-3 fill-current top-1 left-0.5', {
                'text-white': voice !== VOICES.ENGAGING || live,
                'text-gray-900': voice === VOICES.ENGAGING && !live,
              })}
              aria-label="active version"
            />
          </>
        ) : null}
      </div>
      <div className="flex items-center space-x-1.25 mt-2.5 text-xs">
        {live ? (
          <>
            <PulsatingDot />
            <span>Live</span>
          </>
        ) : null}
        {name ? (
          <>
            {live ? <div className="w-[3px] h-[3px] rounded-full bg-gray-200 mx-1.25" /> : null}
            <span
              className={classNames({
                'text-white': !voice,
                'text-voice-confident': voice === VOICES.CONFIDENT,
                'text-voice-engaging': voice === VOICES.ENGAGING,
                'text-voice-friendly': voice === VOICES.FRIENDLY,
                'text-voice-professional': voice === VOICES.PROFESSIONAL,
                'text-voice-reassuring': voice === VOICES.REASSURING,
              })}
            >
              {name}
            </span>
          </>
        ) : null}
        {!live && updatedAt ? (
          <>
            <div className="w-[3px] h-[3px] rounded-full bg-gray-200 mx-1.25" />
            <span className="text-gray-200">{displayDate}</span>
          </>
        ) : null}
      </div>
      {live ? (
        <div className="flex items-center space-x-1.25 text-xs">
          {updatedAt ? <span className="text-gray-200">{displayDate}</span> : null}
        </div>
      ) : null}
    </motion.button>
  );
}
