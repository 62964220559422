import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

import TriangleIcon from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg?react';
import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';

export function Select({
  value,
  onChange,
  options,
  placeholder,
  disabled,
  label,
  buttonClassName,
  position = 'bottom',
  ...props
}) {
  const getDisplayLabel = (option) => {
    return option?.label ?? option?.value;
  };

  const buttonLabel = getDisplayLabel(options.find((x) => x.value === value));
  const showPlaceHolder = !buttonLabel;

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open }) => (
        <div className="relative text-sm">
          {!!label && (
            <Listbox.Label className={classNames('text-gray-200 mb-1.25 leading-none block')}>{label}</Listbox.Label>
          )}
          <Listbox.Button
            className={classNames(
              'relative w-full cursor-pointer rounded-md pl-3 pr-8 focus:outline-none h-[30px]',
              'text-left leading-none',
              'focus-visible:outline-white focus-visible:outline-dashed bg-gray-500',
              buttonClassName,
              {
                'text-white': !disabled && !showPlaceHolder,
                'text-white/30': disabled && !showPlaceHolder,
                'text-gray-300': showPlaceHolder,
              },
            )}
            {...props}
          >
            <span className="block truncate">{buttonLabel ?? placeholder}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
              <TriangleIcon className="h-2 w-2 fill-current text-white/50" />
            </span>
          </Listbox.Button>
          <Transition
            appear={true}
            show={open}
            as={Fragment}
            leave="transition-opacity ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            enter="transition-opacity ease-in duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
          >
            <Listbox.Options
              static
              className={classNames(
                'absolute z-10 rounded p-1.25 focus:outline-none w-full',
                'max-h-[300px] !overflow-y-auto styled-scrollbar',
                'bg-gray-600 shadow-md',
                {
                  'mt-1': position === 'bottom',
                  'bottom-full mb-1': position === 'top',
                },
              )}
            >
              {options
                .filter((x) => x.value !== null)
                .map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames('relative flex min-h-[25px] select-none items-center py-1 pl-7 pr-3', {
                        'bg-blue-500 rounded cursor-pointer': active,
                        'text-white/30 cursor-not-allowed': option.disabled,
                      })
                    }
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {({ selected }) => (
                      <>
                        <span className="block grow">{getDisplayLabel(option)}</span>
                        {selected ? (
                          <span className="absolute left-0 top-0 flex h-[25px] items-center pl-2.5">
                            <TickIcon className="h-2.5 w-2.5 fill-current" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
