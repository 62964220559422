import classNames from 'classnames';

import CloseIcon from '@crazyegginc/hatch/dist/images/icon-cross.svg?react';

export function CloseButton({ onClick, buttonClass, iconClass, label = 'close' }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(
        'absolute right-5 top-5 -my-0.5 -mr-0.5 flex h-4 w-4 items-center justify-center',
        'outline-none focus:outline-none focus-visible:outline-white focus-visible:outline-dashed',
        'text-white/40 hover:text-white/80',
        buttonClass,
      )}
    >
      <CloseIcon className={classNames('h-3 w-3 fill-current', iconClass)} aria-label={label} />
    </button>
  );
}
