import { createMachine, assign } from 'xstate';

const MODAL_STATES = {
  IDLE: 'idle',
  SHOW: 'show',
};

export const MODAL_ACTIONS = {
  CLOSE: 'close',
  SHOW: 'show',
};

export const modalMachine = createMachine({
  context: { component: null },
  initial: MODAL_STATES.IDLE,
  states: {
    [MODAL_STATES.IDLE]: {
      on: {
        [MODAL_ACTIONS.SHOW]: {
          actions: assign({
            component: ({ event }) => event.component,
          }),
          target: MODAL_STATES.SHOW,
        },
      },
    },
    [MODAL_STATES.SHOW]: {
      on: {
        [MODAL_ACTIONS.CLOSE]: {
          actions: assign({
            component: null,
          }),
          target: MODAL_STATES.IDLE,
        },
        [MODAL_ACTIONS.SHOW]: {
          actions: assign({
            component: ({ event }) => event.component,
          }),
        },
      },
    },
  },
});
