import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Select } from '../basic-ui/Select';
import { ACTIONS } from '../../../state';
import { VOICE_OPTIONS } from '../../constants';

export function VoiceSelector({ wrapperClassName, selectProps = {} }) {
  const { useSelector, machine } = useMainMachine();
  const voice = useSelector(({ context }) => context.voice);

  return (
    <div className={classNames('w-[160px]', wrapperClassName)}>
      <Select
        aria-label="voice selector"
        options={VOICE_OPTIONS}
        value={voice}
        onChange={(v) => machine.send({ type: ACTIONS.CHANGE_VOICE, voice: v })}
        {...selectProps}
      />
    </div>
  );
}
