import { useState } from 'react';
import { motion } from 'framer-motion';

import { useMainMachine } from '../../../utils/useMainMachine';
import { DisclosureButton } from '../basic-ui/DisclosureButton';
import { ACTIONS, STATES } from '../../../state';
import { MODES } from '../../constants';
import { AISuggestions } from './AISuggestions';
import { TextEditor } from './TextEditor';
import { getVoice } from '../../../utils/utils';

export function Controls() {
  const { useSelector, machine } = useMainMachine();
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const isEditorMode = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.MODES]: MODES.EDITOR } }),
  );
  const showAISuggestions = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.MODES]: { [STATES.EDITOR]: STATES.AI_SUGGESTIONS } } }),
  );
  const voice = useSelector(({ context }) => context.voice);
  const [usedVoice, setUsedVoice] = useState(getVoice(voice));
  const open = useSelector(({ context }) => context.controlsOpen);

  if (!isEditorMode || !selectedElement) return null;

  return (
    <div className="flex flex-col" data-testid="text-controls">
      <div className="h-px bg-gray-600 my-5 -mx-5" />

      <div className="flex items-center justify-between">
        <div className="w-full">
          {showAISuggestions ? (
            <button type="button" onClick={() => machine.send({ type: ACTIONS.BACK })}>
              Back
            </button>
          ) : (
            <span className="font-semibold">Text</span>
          )}
        </div>
        {showAISuggestions ? (
          <div className="w-full flex justify-center font-semibold whitespace-nowrap">AI suggestions</div>
        ) : null}
        <div className="w-full flex justify-end">
          <DisclosureButton
            open={open}
            onClick={() => machine.send({ type: ACTIONS.TOGGLE_CONTROLS })}
            contentId="controls-content"
          />
        </div>
      </div>
      <div className="">
        <motion.div
          initial={false}
          animate={
            open
              ? {
                  height: 'auto',
                  opacity: 1,
                }
              : {
                  height: 0,
                  opacity: 0,
                }
          }
          transition={{ duration: 0.4, ease: 'linear' }}
          className="mt-2.5 flex flex-col"
          id="controls-content"
          data-testid="controls-content"
        >
          {showAISuggestions ? (
            <AISuggestions usedVoice={usedVoice} setUsedVoice={setUsedVoice} />
          ) : (
            <TextEditor setUsedVoice={setUsedVoice} />
          )}
        </motion.div>
      </div>
    </div>
  );
}
