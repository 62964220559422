import { useState, useMemo } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';

import { useMainMachine } from '../../../utils/useMainMachine';
import { useCrazyAI } from '../../../ai/useCrazyAI';
import { TextArea } from '../basic-ui/TextArea';
import { VoiceAndGenerate } from './VoiceAndGenerate';
import { getVoice } from '../../../utils/utils';
import { getTagCount, removeSVGs, restoreSVGs, getHTMLSuggestionContent, isStringHTML } from '../../../utils/html';
import { AI_FEEDBACK_TYPES, VOICES } from '../../constants';

import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';
import { ACTIONS } from '../../../state/actions';

const SUGGESTION_COUNT = 3;

export function AISuggestions({ usedVoice, setUsedVoice }) {
  const { useSelector, machine } = useMainMachine();
  const voice = useSelector(({ context }) => context.voice);
  const selectedElement = useSelector(({ context }) => context.selectedElement);
  const pageContext = useSelector(({ context }) => context.pageContext);
  const [selectedSuggestion, setSelectedSuggestion] = useState();

  const { html, text, type, closestHeadline, placeholder } = selectedElement;

  const isInput = selectedElement.tagName === 'INPUT';
  const isHTML = isInput ? false : isStringHTML(html);
  let content;
  if (isInput) {
    content = placeholder;
  } else {
    content = isHTML ? html : text;
  }
  const [stableContent, setStableContent] = useState(content);
  const [origElement] = useState(selectedElement);

  const params = useMemo(() => {
    const result = {
      url: pageContext.url,
      content: removeSVGs(stableContent),
      title: pageContext.title || '',
      meta_description: pageContext.meta_description || '',
      voice: usedVoice,
      suggestion_count: SUGGESTION_COUNT,
      element_type: type,
    };
    if (type !== 'headline') {
      result.nearest_headline = closestHeadline || '';
    }
    return result;
  }, [pageContext, stableContent, usedVoice, closestHeadline, type]);

  const context = useMemo(
    () => ({
      url: `${location.pathname}${location.search}`,
    }),
    [],
  );

  const { data, sendFeedback, refetch } = useCrazyAI({
    type: `page-editor-generator`,
    params,
    context,
  });

  const origTagCount = getTagCount(removeSVGs(stableContent));

  const list = data?.text
    .split(/\n-|^-/)
    .slice(1)
    .filter((x) => getTagCount(x) === origTagCount)
    .map((x) => x.trim().replace(/[<>/]*$/, ''));

  function applySuggestion(suggestion) {
    setSelectedSuggestion(suggestion);
    if (isInput) {
      machine.send({
        type: ACTIONS.SET_SELECTED_EL_ATTRIBUTE,
        attribute: 'placeholder',
        value: suggestion,
        voice: usedVoice,
      });
    } else if (isHTML) {
      const newHTML = restoreSVGs(stableContent, suggestion);
      machine.send({ type: ACTIONS.SET_SELECTED_EL_HTML, html: newHTML, voice: usedVoice });
    } else {
      machine.send({ type: ACTIONS.SET_SELECTED_EL_TEXT, text: suggestion, voice: usedVoice });
    }

    sendFeedback(AI_FEEDBACK_TYPES.IMPLICIT_POSITIVE);
  }

  function revertSuggestion() {
    setSelectedSuggestion(null);
    if (isInput) {
      machine.send({
        type: ACTIONS.SET_SELECTED_EL_ATTRIBUTE,
        attribute: 'placeholder',
        value: stableContent,
      });
    } else if (isHTML) {
      machine.send({ type: ACTIONS.SET_SELECTED_EL_HTML, html: stableContent });
    } else {
      machine.send({ type: ACTIONS.SET_SELECTED_EL_TEXT, text: stableContent });
    }
  }

  return (
    <>
      <TextArea
        value={isInput ? origElement.placeholder : origElement.text}
        rows={2}
        readOnly
        label="Content"
        labelClassName="!font-normal !text-gray-200"
        className="focus:!border-transparent !cursor-default"
      />
      <div data-testid="ai-suggestions">
        <>
          {list?.map((suggestion) => (
            <Suggestion
              key={suggestion}
              onClick={() => (selectedSuggestion === suggestion ? revertSuggestion() : applySuggestion(suggestion))}
              selected={selectedSuggestion === suggestion}
              anySelected={!!selectedSuggestion}
              usedVoice={usedVoice}
              disabled={!data?.ready}
            >
              {isHTML ? getHTMLSuggestionContent(suggestion) : suggestion}
            </Suggestion>
          ))}
          {Array.from({ length: SUGGESTION_COUNT - (list?.length ?? 0) }).map((x, i) => (
            <div
              key={SUGGESTION_COUNT - i}
              className={classNames(
                'w-[240px] h-[70px] mt-2.5 rounded-md bg-gray-500/40 animate-skeleton',
                'bg-gradient-to-r from-gray-500/40 via-[20%] via-gray-500/10 to-[40%] to-gray-500/40 bg-[length:600px]',
              )}
            />
          ))}
        </>
      </div>
      <Transition
        show={Boolean(data?.ready)}
        enter="transition-opacity duration-700 delay-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-0"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <VoiceAndGenerate
          onClick={() => {
            setUsedVoice(getVoice(voice));
            setSelectedSuggestion(null);
            if (content === stableContent) {
              refetch();
            } else {
              setStableContent(content);
            }
          }}
        />
      </Transition>
    </>
  );
}

function Suggestion({ children, onClick, selected, anySelected, usedVoice, disabled }) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(
        'text-sm text-left mt-2.5 p-2.5 rounded-md border',
        'relative',
        usedVoice === VOICES.CONFIDENT && {
          'bg-voice-confident/10 text-voice-confident': true,
          'border-voice-confident': selected,
          'border-voice-confident/50 hover:border-voice-confident opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-confident/50 hover:border-voice-confident': !selected && !anySelected,
        },
        usedVoice === VOICES.ENGAGING && {
          'bg-voice-engaging/10 text-voice-engaging': true,
          'border-voice-engaging': selected,
          'border-voice-engaging/50 hover:border-voice-engaging opacity-50 hover:opacity-100': !selected && anySelected,
          'border-voice-engaging/50 hover:border-voice-engaging': !selected && !anySelected,
        },
        usedVoice === VOICES.FRIENDLY && {
          'bg-voice-friendly/10 text-voice-friendly': true,
          'border-voice-friendly': selected,
          'border-voice-friendly/50 hover:border-voice-friendly opacity-50 hover:opacity-100': !selected && anySelected,
          'border-voice-friendly/50 hover:border-voice-friendly': !selected && !anySelected,
        },
        usedVoice === VOICES.PROFESSIONAL && {
          'bg-voice-professional/10 text-voice-professional': true,
          'border-voice-professional': selected,
          'border-voice-professional/50 hover:border-voice-professional opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-professional/50 hover:border-voice-professional': !selected && !anySelected,
        },
        usedVoice === VOICES.REASSURING && {
          'bg-voice-reassuring/10 text-voice-reassuring': true,
          'border-voice-reassuring': selected,
          'border-voice-reassuring/50 hover:border-voice-reassuring opacity-50 hover:opacity-100':
            !selected && anySelected,
          'border-voice-reassuring/50 hover:border-voice-reassuring': !selected && !anySelected,
        },
      )}
      onClick={onClick}
    >
      {selected && (
        <div
          className={classNames(
            'absolute top-0 right-0 w-[17px] h-[17px] rounded-bl rounded-tr flex items-center justify-center text-gray-700',
            {
              'bg-voice-confident': usedVoice === VOICES.CONFIDENT,
              'bg-voice-engaging': usedVoice === VOICES.ENGAGING,
              'bg-voice-friendly': usedVoice === VOICES.FRIENDLY,
              'bg-voice-professional': usedVoice === VOICES.PROFESSIONAL,
              'bg-voice-reassuring': usedVoice === VOICES.REASSURING,
            },
          )}
        >
          <TickIcon className="h-2 w-2 fill-current" aria-label="selected suggestion" />
        </div>
      )}
      {children}
    </button>
  );
}
