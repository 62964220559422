import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { MODES } from '../../constants';
import { ACTIONS, STATES } from '../../../state';

import SparklesIcon from '@crazyegginc/hatch/dist/images/icon-sparkles-filled.svg?react';
import EditorIcon from '@crazyegginc/hatch/dist/images/icon-editor.svg?react';

export function ModeSelector({ disabled }) {
  const { machine, useSelector } = useMainMachine();
  const mode = useSelector((state) =>
    state.matches({ [STATES.INITIALIZED]: { [STATES.MODES]: STATES.EDITOR } }) ? MODES.EDITOR : MODES.GENERATOR,
  );

  return (
    <RadioGroup value={mode} onChange={(value) => machine.send({ type: ACTIONS.CHANGE_MODE, mode: value })}>
      <div className="flex items-center space-x-1">
        <RadioGroup.Option value={MODES.GENERATOR}>
          {({ checked }) => (
            <Item checked={checked} disabled={disabled}>
              <SparklesIcon className="w-4 h-4 fill-current" />
              <span>Generator</span>
            </Item>
          )}
        </RadioGroup.Option>
        <RadioGroup.Option value={MODES.EDITOR}>
          {({ checked }) => (
            <Item checked={checked} disabled={disabled}>
              <EditorIcon className="w-4 h-4 fill-current" />
              <span>Editor</span>
            </Item>
          )}
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
}

export function Item({ checked, children, disabled }) {
  return (
    <div
      className={classNames(
        'rounded-md duration-75 hover:transition-all h-[30px] px-2.5',
        'flex items-center justify-center space-x-2 cursor-pointer',
        'outline-none focus:outline-none focus-visible:outline-white focus-visible:outline-dashed',
        {
          'text-white bg-gray-500': checked && !disabled,
          'text-gray-200 hover:bg-gray-600': !checked && !disabled,
          'cursor-not-allowed text-white/30': disabled,
        },
      )}
    >
      {children}
    </div>
  );
}
