export function inflect(input, count) {
  if (count === 1) {
    return input;
  } else {
    return `${input}s`;
  }
}

export function capitalizeFirstLetter(input) {
  return `${input.charAt(0).toUpperCase()}${input.slice(1)}`;
}
