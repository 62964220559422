import classNames from 'classnames';

import { useMainMachine } from '../utils/useMainMachine';
import { useLockBodyScroll } from '../utils/useLockBodyScroll';
import { CloseButton } from '../editor-ui/components/basic-ui/CloseButton';
import { Button } from '../editor-ui/components/basic-ui/Button';
import { MODAL_ACTIONS } from './modalMachine';

export function Modal({
  children,
  overlayClassName,
  dialogClassName,
  overlay = true,
  disableOutsideClick = false,
  dismissable = true,
  onDismiss = null,
  ...props
}) {
  useLockBodyScroll();
  const { machine } = useMainMachine();

  return (
    <div
      className={classNames('fixed inset-0 z-[99998]', overlayClassName, {
        'bg-gray-900/65': overlay,
        'bg-transparent': !overlay,
      })}
      data-testid="overlay"
      onMouseDown={(e) => {
        e.stopPropagation();
        if (disableOutsideClick) return;
        onDismiss?.();
        machine.send({ type: MODAL_ACTIONS.CLOSE });
      }}
    >
      <div
        role="dialog"
        className={classNames(
          'absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col overflow-auto',
          'z-[99999] max-h-[70%] w-[470px] rounded-lg px-10 pb-[30px] pt-10',
          'bg-gray-900 text-white text-sm shadow-md',
          dialogClassName,
        )}
        onMouseDown={(e) => e.stopPropagation()}
        {...props}
      >
        {dismissable && (
          <CloseButton
            label="close modal"
            onClick={() => {
              onDismiss?.();
              machine.send({ type: MODAL_ACTIONS.CLOSE });
            }}
          />
        )}
        {children}
      </div>
    </div>
  );
}

function ModalTitle({ children, className }) {
  return <h2 className={classNames('text-white mb-1.25 text-2xl font-bold', className)}>{children}</h2>;
}

function ModalActions({ children, className }) {
  return <div className={classNames('mt-10 flex items-center space-x-2.5 justify-end', className)}>{children}</div>;
}

function ModalCancelButton({ onClick, children, ...props }) {
  const { machine } = useMainMachine();

  return (
    <Button
      variant="cancel"
      size="lg"
      onClick={() => {
        onClick?.();
        machine.send({ type: MODAL_ACTIONS.CLOSE });
      }}
      {...props}
    >
      {children ? children : 'Cancel'}
    </Button>
  );
}

Modal.Title = ModalTitle;
Modal.Actions = ModalActions;
Modal.Cancel = ModalCancelButton;
