import { useState, useEffect } from 'react';

import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';
import { Input } from '../basic-ui/Input';

import LinkIcon from '@crazyegginc/hatch/dist/images/icon-link-outline.svg?react';

export function MobileAccessModal() {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
  }, [copied]);

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location);
    setCopied(true);
  };

  return (
    <Modal dialogClassName="!w-[330px]" dismissable={false} disableOutsideClick={true} overlayClassName="!bg-gray-400">
      <div className="text-sm text-gray-200 mb-6">
        <p className="mb-1">Our editor is not currently available on devices with small screens.</p>
        <p>We recommending using the link below to open the editor on your desktop.</p>
      </div>
      <div className="relative">
        <Input size="lg" value={window.location} className="!pr-24" readOnly />
        <Button className="absolute right-1 top-1/2 -translate-y-1/2" onClick={() => copyUrl()}>
          <LinkIcon className="fill-current w-3 h-3 mr-2" />
          {copied ? 'Copied' : 'Copy'}
        </Button>
      </div>
    </Modal>
  );
}
