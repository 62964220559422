export function MobileCategory({ name }) {
  return <div className="text-white cursor-default font-semibold">{name}</div>;
}

export function MobileItem({ name, dimension }) {
  return (
    <div className="flex items-center justify-between w-full grow pl-2.5 space-x-2">
      <span>{name}</span>
      <span>{dimension}</span>
    </div>
  );
}
