import { VoiceSelector } from '../_top/VoiceSelector';
import { Button } from '../basic-ui/Button';

import SparklesIcon from '@crazyegginc/hatch/dist/images/icon-sparkles-filled.svg?react';

export function VoiceAndGenerate({ onClick, disabled }) {
  return (
    <div className="flex item-center justify-between mt-2.5">
      <VoiceSelector
        wrapperClassName="!w-[137px]"
        selectProps={{ position: 'top', buttonClassName: '!text-xs !pl-2.5' }}
      />
      <Button className="!px-[7px]" disabled={disabled} onClick={onClick}>
        <div className="flex items-center space-x-1">
          <SparklesIcon className="w-4 h-4 fill-current" />
          <span>Generate</span>
        </div>
      </Button>
    </div>
  );
}
