import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Button } from '../basic-ui/Button';
import { Spinner } from '../basic-ui/Spinner';
import { ACTIONS } from '../../../state';

import TickIcon from '@crazyegginc/hatch/dist/images/icon-tick-basic.svg?react';
import TriangleIcon from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg?react';

export function SaveButton() {
  const { machine, useSelector } = useMainMachine();
  const createdWithGenerator = useSelector(({ context }) => !context.doneManualEdits && context.generatorVoiceUsed);
  const isDirty = useSelector(({ context }) => context.isDirty);
  const canUndo = useSelector(({ context }) => context.canUndo);
  const hasVersions = useSelector(
    ({ context }) => context.editor.pages.find((p) => p.id === context.activePageId)?.versions?.length > 0,
  );
  const isActiveLive = useSelector(
    ({ context }) =>
      context.activeVersionId == null ||
      context.editor.pages
        .find((p) => p.id === context.activePageId)
        ?.versions.find((v) => v.id === context.activeVersionId)?.published_at != null,
  );
  const isSaving = useSelector(({ context }) => context.saving);
  const isSaved = useSelector(({ context }) => context.saved);

  const disabled = !isDirty || isSaving || !canUndo;
  const title = isSaved && !isDirty ? 'Saved' : 'Save';

  const save = () => {
    machine.send({ type: ACTIONS.SAVE });
  };
  const saveAsNew = () => {
    machine.send({ type: ACTIONS.SAVE_AS_NEW });
  };

  /* The following cases will always save as a new version: 
   - there are no versions yet
   - the active version is the Live one
   - If the changes were made using the generator only
  */
  if (!hasVersions || isActiveLive || createdWithGenerator || isSaving) {
    return (
      <Button onClick={saveAsNew} className="ml-2.5" disabled={disabled} data-testid="save-button">
        {isSaving ? (
          <>
            <Spinner className="w-4 h-4 mr-2" />
            Saving...
          </>
        ) : (
          <>
            <TickIcon className="h-3 w-3 fill-current mr-2" />
            {title}
          </>
        )}
      </Button>
    );
  }

  return (
    <div className="flex items-center h-[30px] ml-2.5 relative">
      <Button
        onClick={save}
        className="!pr-2 !rounded-r-none !border-r !border-r-gray-900/40"
        disabled={disabled}
        data-testid="save-button"
      >
        {title}
      </Button>
      <Popover>
        <Popover.Button as={Button} className="!px-3 !rounded-l-none !border-l-0" disabled={disabled}>
          <TriangleIcon className="h-2 w-2 fill-current" aria-label="saving options" />
        </Popover.Button>
        <Transition
          appear={true}
          as={Fragment}
          leave="transition-opacity ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          enter="transition-opacity ease-in duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
        >
          <Popover.Panel className="absolute z-10 left-0 top-full bg-gray-600 rounded p-1.25 shadow-md flex flex-col w-[120px]">
            {({ close }) => (
              <>
                <Item
                  onClick={() => {
                    save();
                    close();
                  }}
                >
                  Save
                </Item>

                <Item
                  onClick={() => {
                    saveAsNew();
                    close();
                  }}
                >
                  Save new
                </Item>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}

function Item({ children, onClick }) {
  return (
    <button
      type="button"
      className={classNames(
        'relative flex min-h-[25px] select-none items-center py-1 px-3',
        'hover:bg-blue-500 hover:rounded hover:cursor-pointer',
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
