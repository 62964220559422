import { useState, useEffect } from 'react';

import { Button } from '../basic-ui/Button';
import { BUTTON_TYPES } from '../../constants';

export function ShareButton() {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  return (
    <div className="relative">
      {copied ? (
        <div className="absolute p-2.5 rounded shadow-md bg-gray-600 bottom-full -translate-y-[10px] left-0 -translate-x-[30px] whitespace-nowrap">
          Sharing link copied!
        </div>
      ) : null}
      <Button
        variant={BUTTON_TYPES.SECONDARY}
        onClick={() => {
          navigator.clipboard.writeText(window.location);
          setCopied(true);
        }}
      >
        Share
      </Button>
    </div>
  );
}
