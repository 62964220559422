import { forwardRef, useId } from 'react';
import classNames from 'classnames';

export const TextArea = forwardRef(
  (
    {
      rows = 3,
      disabled = false,
      onChange,
      value = '',
      id,
      label = '',
      placeholder = null,
      error = '',
      className,
      labelClassName,
      resizable = 'none',
      style = {},
      ...props
    },
    ref,
  ) => {
    const errorId = `textarea-error-${useId()}`;
    let inputId = useId();
    inputId = id ?? inputId;

    return (
      <div className="w-full">
        {!!label && (
          <label
            htmlFor={inputId}
            className={classNames('mb-1.25 block text-white font-semibold leading-none', labelClassName)}
          >
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          className={classNames(
            'block min-h-[35px] w-full rounded-md border border-transparent outline-none focus:outline-none px-3.5 py-2.5',
            'text-sm leading-normal placeholder:text-gray-300 styled-scrollbar',
            className,
            {
              'focus:border-blue-500': !error,
              'bg-gray-500 text-white': !disabled,
              'bg-gray-600 text-gray-200': disabled,
              'border-red-500 focus:border-red-500': !!error,
              'resize ': resizable === 'both',
              'resize-x': resizable === 'x',
              'resize-y': resizable === 'y',
              'resize-none': resizable === 'none',
            },
          )}
          id={inputId}
          value={value}
          disabled={disabled}
          onChange={onChange}
          placeholder={placeholder}
          aria-invalid={!!error}
          aria-describedby={error ? errorId : null}
          style={{ height: `calc((${rows}em * 1.5) + 22px)`, ...style }}
          {...props}
        />
        {!!error && (
          <div className="relative h-5" id={errorId}>
            <div className="text-red-500 absolute translate-y-0.5 whitespace-nowrap">{error}</div>
          </div>
        )}
      </div>
    );
  },
);
