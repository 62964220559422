import { useMainMachine } from '../../../utils/useMainMachine';

import { ACTIONS } from '../../../state';
import { HtmlContent } from './HtmlContent';
import { getVoice } from '../../../utils/utils';
import { VoiceAndGenerate } from './VoiceAndGenerate';
import { NarrowSelectedElement } from './NarrowSelectedElement';

export function TextEditor({ setUsedVoice }) {
  const { machine, useSelector } = useMainMachine();

  const voice = useSelector(({ context }) => context.voice);
  const selectedElement = useSelector(({ context }) => context.selectedElement);

  const showContent =
    selectedElement.hasImmediateChildTextNode ||
    selectedElement.tagName === 'INPUT' ||
    selectedElement.childsWithTextNode.length === 0;

  return (
    <>
      {showContent ? <HtmlContent /> : <NarrowSelectedElement />}
      <VoiceAndGenerate
        onClick={() => {
          machine.send({ type: ACTIONS.AI_REPHRASE });
          setUsedVoice(getVoice(voice));
        }}
      />
    </>
  );
}
