import { useMainMachine } from '../../../utils/useMainMachine';

export function PageInfo() {
  const { useSelector } = useMainMachine();
  const url = useSelector(({ context }) => context.pageContext?.url);
  const title = useSelector(({ context }) => context.pageContext?.title);

  return (
    <div className="truncate">
      <div className="font-semibold truncate" data-testid="pagetitle">
        {title}
      </div>
      <div className="text-gray-200 truncate" data-testid="pageurl">
        {url}
      </div>
    </div>
  );
}
