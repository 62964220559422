export function extractContent(html) {
  return parseHTMLfromString(html).documentElement.textContent;
}

const svgRegex = /<svg.*?>[\s\S]*?<\/svg>/gi;
const tagRegex = /<[^/](.*?)>|<\/(.*?)>/gi;

export function removeSVGs(html) {
  return html.replace(svgRegex, '');
}

export function removeComments(html) {
  return html.replace(/<!--.*?-->/g, '');
}

export function getTagCount(html) {
  return parseHTMLfromString(html).documentElement.getElementsByTagName('*').length;
}

export function getInnerHtml(html) {
  return parseHTMLfromString(html).body.firstChild.innerHTML;
}

export function changeInnerHtml(el, inner) {
  const html = parseHTMLfromString(el);
  html.body.firstChild.innerHTML = inner;
  return html.body.innerHTML;
}

export function restoreSVGs(orig, updated) {
  const svgMatches = [...orig.matchAll(svgRegex)];

  let result = updated;
  svgMatches.forEach((svg) => {
    const origTagCount = (orig.slice(0, svg.index).match(tagRegex) || []).length;
    const tagsInNew = [...result.matchAll(tagRegex)];
    const indexToInsert = tagsInNew[origTagCount - 1].index + tagsInNew[origTagCount - 1][0].length;
    result = result.slice(0, indexToInsert) + svg + result.slice(indexToInsert);
  });
  return result;
}

export function parseHTMLfromString(string) {
  return new DOMParser().parseFromString(string, 'text/html');
}

function hasImmediateChildTextNode(el) {
  return [...el.childNodes].some((x) => x.nodeType === Node.TEXT_NODE && x.textContent.trim());
}

function getChildrenWithTextNode(el) {
  let children = [...el.children].filter((x) => x.textContent.trim());

  if (children.length === 1 && !hasImmediateChildTextNode(children[0])) {
    return getChildrenWithTextNode(children[0]);
  }

  return children;
}

function getTextContentRecursively(el) {
  if (hasImmediateChildTextNode(el)) return [el.textContent];
  return [...getChildrenWithTextNode(el)].flatMap(getTextContentRecursively);
}

export function getHTMLSuggestionContent(suggestion) {
  return getTextContentRecursively(parseHTMLfromString(suggestion).body.firstChild).join(' > ');
}

export function isStringHTML(string) {
  return getTagCount(removeComments(string)) > 3;
}
