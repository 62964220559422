import * as yup from 'yup';
import { Formik, Form } from 'formik';

import { useMainMachine } from '../../../utils/useMainMachine';
import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';
import { Input } from '../basic-ui/Input';
import { ensureProtocol } from '../../../utils/url';
import { STATES, ACTIONS } from '../../../state';
import { Spinner } from '../basic-ui/Spinner';

import WarningIcon from '@crazyegginc/hatch/dist/images/icon-warning-filled.svg?react';

const validationSchema = yup.object().shape({
  url: yup.string().required('Please provide a URL.').url('Please provide a valid URL.'),
});

export function AddFirstPageModal({ ...props }) {
  const { useSelector, machine } = useMainMachine();
  const isLoading = useSelector((state) => state.matches(STATES.ADDING_FIRST_PAGE));
  const failedToLoadUrl = useSelector(({ context }) => context.failedToLoadUrl);

  return (
    <Modal
      dialogClassName="!top-[150px] !translate-y-0"
      dismissable={false}
      disableOutsideClick={true}
      overlayClassName="!bg-gray-500"
      {...props}
    >
      {failedToLoadUrl ? (
        <div className="flex flex-col items-center">
          <WarningIcon className="w-9 h-9 fill-current text-[#FFB202]" />
          <div className="mt-2 text-2xl font-semibold">Error loading page</div>
          <div className="text-gray-200 text-center">
            It appears the page <span className="text-white">{failedToLoadUrl}</span> is blocked from loading our
            editor.
          </div>
        </div>
      ) : (
        <>
          <Modal.Title>What page do you want to edit?</Modal.Title>

          <p>Add a page to start editing.</p>
        </>
      )}
      <Formik
        initialValues={{ url: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          machine.send({
            type: ACTIONS.ADD_PAGE,
            url: values.url,
          });
        }}
      >
        {({ values, errors, touched, setFieldValue, handleBlur }) => (
          <Form>
            <div className="mt-[30px]">
              <Input
                label={failedToLoadUrl ? 'Try another page URL' : 'Page URL'}
                name="url"
                size="lg"
                value={values.url}
                onChange={async (e) => {
                  await setFieldValue('url', ensureProtocol(e.target.value), true);
                }}
                onBlur={handleBlur}
                error={touched.url && errors.url ? errors.url : null}
                autoFocus
              />
            </div>

            <Modal.Actions>
              <Button type="submit" size="lg" disabled={isLoading}>
                {isLoading ? (
                  <>
                    <Spinner />
                    <span className="ml-2">Adding...</span>
                  </>
                ) : (
                  'Add Page'
                )}
              </Button>
            </Modal.Actions>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
