import classNames from 'classnames';

import ExpandIcon from '@crazyegginc/hatch/dist/images/icon-arrow-expand.svg?react';

export function DisclosureButton({ open, onClick, contentId }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="p-1 text-gray-300 hover:text-white"
      aria-expanded={open}
      aria-controls={contentId}
    >
      <ExpandIcon
        aria-label={open ? 'close section' : 'open section'}
        className={classNames('w-3 h-3 fill-current', {
          'rotate-180': open,
          'rotate-90': !open,
        })}
      />
    </button>
  );
}
