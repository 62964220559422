import { Fragment } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { getDomain } from 'tldts';

import { useMainMachine } from '../../../utils/useMainMachine';
import { DisclosureButton } from '../basic-ui/DisclosureButton';
import { inflect } from '../../../utils/string';
import { ACTIONS } from '../../../state/actions';

import AddIcon from '@crazyegginc/hatch/dist/images/icon-add-circle-filled.svg?react';
import PageIcon from '@crazyegginc/hatch/dist/images/icon-browser.svg?react';

export function Pages() {
  const { useSelector, machine } = useMainMachine();
  const pages = useSelector(({ context }) => context.editor.pages);
  const activePageId = useSelector(({ context }) => context.activePageId);
  const open = useSelector(({ context }) => context.pagesOpen);

  const domains = {};
  pages.forEach((p) => {
    const domain = getDomain(p.url);
    domains[domain] = [...(domains[domain] ?? []), p];
  });

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <div>
          <span className="font-semibold">Pages</span>
          <span className="ml-2.5 text-gray-200">
            {pages.length} {inflect('page', pages.length)}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            type="button"
            onClick={() => {
              machine.send({ type: ACTIONS.SHOW_ADD_PAGE });
            }}
            className="p-1 text-gray-200 hover:text-white"
          >
            <AddIcon className="w-4 h-4 fill-current" aria-label="Add page" />
          </button>
          <DisclosureButton
            open={open}
            onClick={() => machine.send({ type: ACTIONS.TOGGLE_PAGES })}
            contentId="pages-content"
          />
        </div>
      </div>
      <div className="overflow-hidden">
        <motion.div
          initial={false}
          animate={
            open
              ? {
                  height: 'auto',
                  opacity: 1,
                }
              : {
                  height: 0,
                  opacity: 0,
                }
          }
          transition={{ duration: 0.4, ease: 'linear' }}
          className="mt-2.5 flex flex-col"
          id="pages-content"
        >
          <motion.div
            className="flex flex-col"
            animate={open ? { opacity: 1 } : { opacity: 0 }}
            transition={{ duration: 0.2, ease: 'linear' }}
          >
            {Object.entries(domains)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([domain, subpages]) => {
                const homepageIndex = subpages.findIndex((p) => {
                  let pathname = new URL(p.url).pathname;
                  return pathname === '/';
                });
                if (homepageIndex === -1) {
                  return (
                    <Fragment key={`${domain}-1`}>
                      <StaticPageItem domain={domain} />
                      <SubPages pages={subpages} activePageId={activePageId} />
                    </Fragment>
                  );
                } else {
                  const page = subpages[homepageIndex];
                  return (
                    <Fragment key={`${domain}-2`}>
                      <PageItem page={page} active={activePageId === page.id} domain={domain} />
                      <SubPages pages={subpages.filter((p, i) => i !== homepageIndex)} activePageId={activePageId} />
                    </Fragment>
                  );
                }
              })}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
}

function SubPages({ pages, activePageId }) {
  return pages.map((page) => <PageItem key={page.id} page={page} active={activePageId === page.id} />);
}

function PageItem({ page, active, domain }) {
  const { machine } = useMainMachine();

  let displayName = domain;
  if (!displayName) {
    displayName = new URL(page.url).pathname;
  }

  return (
    <button
      type="button"
      className={classNames('flex items-center justify-between px-2.5 h-[30px] rounded-md', {
        'bg-gray-500 text-white': active,
        'text-gray-200 hover:bg-gray-600': !active,
        'ml-5': !domain,
      })}
      onClick={() => machine.send({ type: ACTIONS.SELECT_PAGE, pageId: page.id })}
    >
      <div className="flex items-center min-w-0">
        <PageIcon className="w-3 h-3 fill-current mr-2 shrink-0" />
        <span className="truncate">{displayName}</span>
      </div>
      <span>{page.versions.length}</span>
    </button>
  );
}

function StaticPageItem({ domain }) {
  return (
    <div className="flex items-center justify-between px-2.5 h-[30px] rounded-md text-gray-200">
      <div className="flex items-center min-w-0">
        <PageIcon className="w-3 h-3 fill-current mr-2 shrink-0" />
        <span className="truncate">{domain}</span>
      </div>
    </div>
  );
}
