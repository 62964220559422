import Honeybadger from '@honeybadger-io/js';

import { isStaging } from './env';

export function sendMetric(metric, params) {
  if (!['production', 'test'].includes(process.env.NODE_ENV)) return; // comment out to test metrex locally
  let url = import.meta.env.VITE_METREX_API_URL;
  if (isStaging()) {
    url = import.meta.env.VITE_METREX_API_STAGING_URL;
  }
  send(url, metric, params);
}

function send(url, metric, params) {
  try {
    fetch(url, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        metric,
        params,
      }),
    });
  } catch (error) {
    Honeybadger.notify(error, 'Failed to send metric');
  }
}
