import classNames from 'classnames';

import { useMainMachine } from '../utils/useMainMachine';
import { NOTIFICATION_TYPES } from '../editor-ui/constants';
import { CloseButton } from '../editor-ui/components/basic-ui/CloseButton';
import { NOTIFICATION_ACTIONS } from './notificationLogic';

import SuccessIcon from '@crazyegginc/hatch/dist/images/icon-tick-circle-filled.svg?react';
import ErrorIcon from '@crazyegginc/hatch/dist/images/icon-cross-circle-filled.svg?react';
import WarningIcon from '@crazyegginc/hatch/dist/images/icon-warning-circle-filled.svg?react';

export function Notification({ data }) {
  const { machine } = useMainMachine();

  if (!data) return null;
  const { type, title, text, extraText, id } = data;

  return (
    <div
      className={classNames('relative flex rounded-xl p-5 shadow-md items-start w-[385px]', {
        'bg-[#482524]': type === NOTIFICATION_TYPES.ERROR,
        'bg-[#143522]': type === NOTIFICATION_TYPES.SUCCESS,
        'bg-[#4D331C]': type === NOTIFICATION_TYPES.WARNING,
      })}
    >
      <CloseButton onClick={() => machine.send({ type: NOTIFICATION_ACTIONS.REMOVE, id })} label="close banner" />

      {type === 'error' ? (
        <ErrorIcon className="mr-3.75 h-5 w-5 fill-current text-red-500 shrink-0" />
      ) : type === 'success' ? (
        <SuccessIcon className="mr-3.75 h-5 w-5 fill-current text-green-500 shrink-0" />
      ) : type === 'warning' ? (
        <WarningIcon className="mr-3.75 h-5 w-5 fill-current text-voice-reassuring shrink-0" />
      ) : null}

      <div className="flex-grow">
        {title && <div className="mb-1 mr-10 text-sm font-bold leading-tight text-white">{title}</div>}
        <div className="text-sm leading-tight text-gray-200 whitespace-pre-wrap">{text}</div>
        <div
          className="mt-1 text-2xs leading-tight text-gray-200 font-mono whitespace-pre-wrap"
          style={{ overflowWrap: 'anywhere', wordBreak: 'break-word' }}
        >
          {extraText}
        </div>
      </div>
    </div>
  );
}
