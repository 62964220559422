import { fromTransition } from 'xstate';
import { getId } from '../utils/utils';

export const NOTIFICATION_ACTIONS = {
  ADD: 'add',
  REMOVE: 'remove',
  REMOVE_ALL: 'removeAll',
};

export const notificationLogic = fromTransition(
  (state, event) => {
    switch (event.type) {
      case NOTIFICATION_ACTIONS.ADD: {
        return { notifications: [{ ...event.notification, id: getId() }, ...state.notifications] };
      }
      case NOTIFICATION_ACTIONS.REMOVE: {
        return { notifications: state.notifications.filter((n) => n.id !== event.id) };
      }
      case NOTIFICATION_ACTIONS.REMOVE_ALL: {
        return { notifications: [] };
      }
      default: {
        return state;
      }
    }
  },
  { notifications: [] },
);
