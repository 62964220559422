import { forwardRef } from 'react';
import classNames from 'classnames';

import { BUTTON_TYPES } from '../../constants';

const { PRIMARY, SECONDARY, PUBLISH, CANCEL } = BUTTON_TYPES;

export const Button = forwardRef(
  (
    {
      component: Component = 'button',
      variant = BUTTON_TYPES.PRIMARY,
      disabled = false,
      onClick,
      className,
      size = 'base',
      type = null,
      children,
      ...props
    },
    ref,
  ) => {
    if (Component === 'button' && type === null) {
      type = 'button';
    }

    return (
      <Component
        ref={ref}
        type={type}
        onClick={onClick}
        className={classNames(
          'rounded-md duration-75 hover:transition-all',
          'flex items-center justify-center font-semibold',
          'outline-none focus:outline-none focus-visible:outline-white focus-visible:outline-dashed',
          className,
          {
            'h-[30px] px-2.5': size === 'base',
            'h-[37px] px-5': size === 'lg',
            'border-2': [PRIMARY, SECONDARY].includes(variant),
            'border ': variant === PUBLISH,
            'bg-blue-500 hover:bg-blue-400 active:bg-blue-600 border-transparent': variant === PRIMARY && !disabled,
            'bg-gray-500 hover:bg-gray-400 active:bg-gray-600 border-transparent': variant === SECONDARY && !disabled,
            'bg-green-500 hover:bg-green-400 active:bg-green-600 border-transparent': variant === PUBLISH && !disabled,
            'bg-transparent text-gray-200 hover:text-white': variant === CANCEL && !disabled,
            'cursor-not-allowed bg-transparent text-white/30': disabled,
            'border-blue-500/30': variant === PRIMARY && disabled,
            'border-gray-500': variant === SECONDARY && disabled,
            'border-green-500/30': variant === PUBLISH && disabled,
          },
        )}
        disabled={disabled}
        {...props}
      >
        {typeof children === 'string' ? <span>{children}</span> : children}
      </Component>
    );
  },
);
