import { useMainMachine } from '../../../utils/useMainMachine';
import { Modal } from '../../../modal/Modal';
import { Button } from '../basic-ui/Button';
import { Spinner } from '../basic-ui/Spinner';
import { ACTIONS } from '../../../state/actions';

export function SaveChangesModal() {
  const { useSelector, machine } = useMainMachine();
  const isSaving = useSelector(({ context }) => context.saving);
  const createdWithGenerator = useSelector(({ context }) => !context.doneManualEdits && context.generatorVoiceUsed);
  const isActiveLive = useSelector(
    ({ context }) =>
      context.activeVersionId == null ||
      context.editor.pages
        .find((p) => p.id === context.activePageId)
        ?.versions.find((v) => v.id === context.activeVersionId)?.published_at != null,
  );

  const saveAsNew = isActiveLive || createdWithGenerator;

  return (
    <Modal dialogClassName="!top-[150px] !translate-y-0" dismissable={false} disableOutsideClick={true}>
      <Modal.Title className="!mb-1">Save changes?</Modal.Title>

      <p>Your unsaved changes will be lost.</p>

      <Modal.Actions>
        <Modal.Cancel disabled={isSaving} onClick={() => machine.send({ type: ACTIONS.CANCEL })} />
        <Button
          size="lg"
          disabled={isSaving}
          onClick={() => {
            machine.send({ type: ACTIONS.DISCARD });
          }}
        >
          Discard changes
        </Button>
        <Button
          size="lg"
          disabled={isSaving}
          onClick={() => machine.send({ type: saveAsNew ? ACTIONS.SAVE_AS_NEW : ACTIONS.SAVE })}
        >
          {isSaving ? (
            <>
              <Spinner />
              <span className="ml-2">Saving...</span>
            </>
          ) : (
            'Save changes'
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
