import classNames from 'classnames';

import { useMainMachine } from '../../../utils/useMainMachine';
import { LOADING } from '../../constants';
import { Modal } from '../../../modal/Modal';
import { Spinner } from './Spinner';

export function FullScreenLoader() {
  const { useSelector } = useMainMachine();
  const showLoader = useSelector((state) => state.hasTag(LOADING));

  if (!showLoader) return null;

  return (
    <Modal
      disableOutsideClick={true}
      dismissable={false}
      dialogClassName={classNames('!bg-transparent !shadow-none')}
      overlayClassName="!bg-gray-900/40"
    >
      <div className="w-full h-full flex justify-center items-center">
        <Spinner className="w-10 h-10 text-gray-400" />
      </div>
    </Modal>
  );
}
