import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';

import { Select } from '../basic-ui/Select';
import { DEVICES, MOBILE_OPTIONS } from '../../constants';
import { useMainMachine } from '../../../utils/useMainMachine';
import { ACTIONS } from '../../../state';

import TriangleIcon from '@crazyegginc/hatch/dist/images/icon-triangle-filled.svg?react';
import DesktopIcon from '@crazyegginc/hatch/dist/images/icon-desktop-outline.svg?react';
import TabletIcon from '@crazyegginc/hatch/dist/images/icon-tablet-outline.svg?react';
import MobileIcon from '@crazyegginc/hatch/dist/images/icon-mobile-outline.svg?react';

const options = [
  {
    value: DEVICES.MOBILE,
    label: 'Mobile',
    Icon: MobileIcon,
  },
  {
    value: DEVICES.TABLET,
    label: 'Tablet',
    Icon: TabletIcon,
  },
  {
    value: DEVICES.DESKTOP,
    label: 'Desktop',
    Icon: DesktopIcon,
  },
];

export function DeviceSwitcher({ ...props }) {
  const { useSelector, machine } = useMainMachine();
  const device = useSelector(({ context }) => context.device);
  const mobile = useSelector(({ context }) => context.mobile);

  const disabled = false;

  return (
    <div className="flex items-center space-x-1.25">
      <Listbox
        value={device}
        onChange={(v) => {
          const mobileOption = MOBILE_OPTIONS.find((x) => x.value === mobile);
          machine.send({
            type: ACTIONS.CHANGE_DEVICE,
            device: v,
            width: mobileOption.width,
            dpr: mobileOption.dpr,
          });
        }}
        disabled={disabled}
      >
        {({ open }) => (
          <div className="relative">
            <Listbox.Button
              className={classNames(
                'relative cursor-pointer rounded-md pl-3 focus:outline-none h-[30px]',
                'text-sm text-left w-[120px]',
                'focus-visible:outline-white focus-visible:outline-dashed bg-gray-500',
                {
                  'text-white': !disabled,
                  'text-white/30': disabled,
                },
              )}
              aria-label="device selector"
              {...props}
            >
              <span className="flex items-center">
                <DeviceItem option={options.find((o) => o.value === device)} />
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                <TriangleIcon className="h-2 w-2 fill-current text-white/50" />
              </span>
            </Listbox.Button>
            <Transition
              appear={true}
              show={open}
              as={Fragment}
              leave="transition-opacity ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enter="transition-opacity ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <Listbox.Options
                static
                className={classNames(
                  'absolute z-10 mt-1 w-[120px] rounded p-1.25 focus:outline-none',
                  'bg-gray-600 shadow-md',
                )}
              >
                {options
                  .filter((x) => x.value !== null)
                  .map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames('relative flex h-[25px] cursor-default select-none items-center py-1 px-3', {
                          'bg-blue-500 rounded cursor-pointer': active,
                        })
                      }
                      value={option.value}
                    >
                      <DeviceItem option={option} />
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
      {device === DEVICES.MOBILE ? (
        <div className="w-[300px] shrink-0">
          <Select
            aria-label="mobile selector"
            value={mobile}
            options={MOBILE_OPTIONS}
            onChange={(v) => {
              const mobileOption = MOBILE_OPTIONS.find((x) => x.value === v);
              machine.send({
                type: ACTIONS.CHANGE_DEVICE,
                device,
                mobile: v,
                width: mobileOption.width,
                dpr: mobileOption.dpr,
                text: `${mobileOption.label.props.name} (${mobileOption.label.props.dimension})`,
              });
            }}
          />
        </div>
      ) : null}
    </div>
  );
}

function DeviceItem({ option }) {
  const { label, Icon } = option;
  return (
    <>
      <Icon className="w-3 h-3 fill-current mr-2" />
      <span className="block">{label}</span>
    </>
  );
}
