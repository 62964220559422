import { createPortal } from 'react-dom';
import { useSelector } from '@xstate/react';

import { useMainMachine } from '../utils/useMainMachine';
import { Notification } from './Notification';
import { NOTIFICATION_LOGIC_ID } from '../state';

export function NotificationCenter() {
  const { useSelector: useMainSelector } = useMainMachine();

  const ref = useMainSelector((state) => state.children[NOTIFICATION_LOGIC_ID].ref);
  const notifications = useSelector(ref, (state) => state.context.notifications);

  return createPortal(<Content notifications={notifications} />, document.body);
}

function Content({ notifications }) {
  if (!notifications || notifications.length === 0) {
    return null;
  }

  return (
    <div className="fixed right-0 top-0 z-[99999] flex max-h-screen flex-col overflow-y-auto p-3.75">
      {notifications.map((notification) => (
        <Notification key={notification.id} data={notification} />
      ))}
    </div>
  );
}
